/**
 * @description:路由鉴权提示
 */
import { ElMessage } from 'element-plus';

const msg = [
    '没有权限那，😄',
    '别试了这个不能看啊，溜了溜了。',
    '这个没啥好看的.🤭',
    '啦啦啦啦'
]

const authRoute = (meta) => ElMessage.warning(
    {
        dangerouslyUseHTMLString: true,
        message: `
                <div class='mb-2'><strong> (●ˇ∀ˇ●)</strong></div>
                <div>【${meta.title}】界面 ${msg[Math.round(Math.random()*3)]}</div>
              `
    }

)

export { authRoute }