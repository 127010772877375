<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
  components: {},
  beforeMount() {
    let maskLoad = document.querySelector(".mask_load");
    maskLoad && maskLoad.remove();
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
html,
body {
  width: 100%;
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}
</style>