/**
 * @description:侧边菜单 状态
 */

export default {
    state: () => {
        return {
            show: true,
        }
    },
    mutations: {
        changShow(state, flag) {
            state.show = flag
        }
    }
}