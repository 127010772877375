/**
 * @description：loading
 */
import { ElLoading } from 'element-plus'
// loading 1
const loading = () => ElLoading.service({
    lock: true,
    text: 'Loading',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)'
});
// loading 2
const loadRoute = () => ElLoading.service({
    lock: true,
    text: ' 开始装载 🚶‍♀️🚶‍♂️🏃‍♀️🏃‍♂️',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)'
});
export { loading, loadRoute }