/**
 * @description:计数器
 */
export default {
    state: () => {
        return {
            count: 1
        }
    },
    mutations: {
        setCount(state, v) {
            state.count = v;
        }
    }
}