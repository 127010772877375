import { createRouter, createWebHistory } from 'vue-router'
import { authRoute } from '@/components/auth-route'
import { loadRoute } from '@/components/ui'

const Door = () => import(/* webpackChunkName: "door" */ '@/views/Door.vue')
const Home = () => import(/* webpackChunkName: "home" */ '@/views/Home.vue')
const Panel = () => import(/* webpackChunkName: "panel" */ '@/views/Panel.vue')
const My = () => import(/* webpackChunkName: "my" */ '@/views/My.vue')
const Play = () => import(/* webpackChunkName: "play" */ '@/views/Play.vue')
const Chart = () => import(/* webpackChunkName: "chart" */ '@/views/Chart.vue')
const Strive = () => import(/* webpackChunkName: "strive" */ '@/views/Strive.vue')
const Video = () => import(/* webpackChunkName: "video" */ '@/views/Video.vue')
const Extension = () => import(/* webpackChunkName: "extension" */ '@/views/Extension.vue')
const Cool = () => import(/* webpackChunkName: "cool" */ '@/views/Cool.vue')

const routes = [
  {
    path: '/door',
    name: 'door',
    component: Door,
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    children: [
      {
        path: '',
        component: Panel
      }, {
        path: '/my',
        component: My
      }, {
        path: '/play',
        meta: {
          // auth: true,
          title: '游戏'
        },
        component: Play
      }, {
        path: '/chart',
        component: Chart
      }, {
        path: '/strive',
        meta: {
          auth: true,
          title: 'Strive'
        },
        component: Strive
      }, {
        path: '/extension',
        component: Extension
      }, {

        path: '/video',
        meta: {
          auth: true,
          title: 'video'
        },
        component: Video
      },{

        path: '/cool',
        meta: {
          auth: true,
          title: 'Cool'
        },
        component: Cool
      },

      
    ]
  },


  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// route遮罩
let load = null;
router.beforeEach(({ meta }, from, next) => {
  from;
  if (meta.auth) {
    if (sessionStorage.getItem('yfauth') == 'lihaile') {
      load = loadRoute();
      next()
    } else {
      load && load.close();
      authRoute(meta);//提示
    }

  } else {
    load = loadRoute();
    next()
  }
})

router.afterEach(() => {
  load && load.close()
})
export default router
